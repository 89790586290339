<template>
    <div class="diagnose xi">
        <hd-com></hd-com>
        <div class="banner">
            <div class="banner-text">
                <h3>检查诊断</h3>
                <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>检查诊断</a></p>
            </div>
        </div>
        <div class="content-wrap">
            <div class="content-top">
                <ul class="diagnose-tab">
                    <li><router-link to="/diagnose/Diagnose">症状诊断</router-link></li>
                    <li class="active"><router-link to="/diagnose/Examine">检查诊断</router-link></li>
                    <li><router-link to="/diagnose/Inspection">检验检测</router-link></li>
                </ul>
            </div>
            <div class="content">
                <el-scrollbar class="left-nav-wrap" style="height: 690px;">
                    <!-- 具体要包裹的内容 -->
                    <div class="left-nav">
                        <h3>检查诊断</h3>
                        <ul class="nav-box">
                            <li v-for="(sideNav,index) in examineList"
                                :key="index"
                                @click="jump(index)"
                                :class="index==0?'active':''"><span></span>{{sideNav.categoryName}}</li>
                        </ul>
                    </div>
                </el-scrollbar>
                <div class="ny_container">
                    <div class="zztabfel clearfix" v-for="(sideNav,index) in examineList" :key="index">
                        <h2>{{sideNav.categoryName}}</h2>
                        <div v-for="(subItem,i) in sideNav.examines" :key="i" :index="subItem.categoryId+''">
                            <a @click="toExamineDetail(subItem.examineId)">{{subItem.examineName}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
    import { Examine } from "../../components/Disease/Examine"
    import hdCom from '../../components/header'
    import ftCom from '../../components/footer'
    import rtBar from "../../components/right";
    export default {
        name: "Examine",
        components: {
            hdCom,
            ftCom,
            rtBar
        },
        data() {
            var examine = new Examine(this.TokenClient, this.Services.Disease)
            return {
                examineList: [],
                rloading: [],//loading遮罩
                examineDomain: examine,
                scroll: '',
            }
        },
        methods: {
            handleScroll() {
                let scrollY = window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                let scrollObj = document.querySelector(".left-nav-wrap");
                //scrollObj 这个是下图右边答题卡的div

                if (scrollY > 300) {
                    scrollObj.style.position = "fixed";
                    scrollObj.style.top = "0";
                } else {
                    scrollObj.style.position = "absolute";
                    scrollObj.style.top = "auto";
                }
            },
            //左侧分类获取
            getExamineNav: function () {
                var _this = this;
                _this.rloading = this.openLoading()
                _this.examineDomain.Examines(function (data) {
                    _this.examineList = (data.data)
                    _this.rloading.close()
                },
                    function (error) {
                        console.log('检查逻辑图请求异常!请刷新重试。', error)
                    })
            },
            //跳转到详情
            toExamineDetail(examineId) {
                ///api/FoodPrescription/{id}/{searchId}/{keyWord}
                this.$router.push({
                    path: '/diagnose/ExamDetail',
                    //name:'foodDetail',
                    query: {
                        id: examineId,
                    }
                })
            },
            dataScroll: function () {
                this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
            },
            jump(index) {
                let jump = document.getElementsByClassName('zztabfel');
                // 获取需要滚动的距离
                let total = jump[index].offsetTop + 200;
                window.scroll(0, total)
                // Chrome
                //document.body.scrollTop = total;
                // Firefox
                //document.documentElement.scrollTop = total;
                // Safari
                //window.pageYOffset = total;
                // $('html, body').animate({
                // 'scrollTop': total
                // }, 400);

            },
            loadSroll: function () {
                var self = this;
                var $navs = $(".nav-box li");
                var sections = document.getElementsByClassName('zztabfel');
                for (var i = sections.length - 1; i >= 0; i--) {
                    if (self.scroll >= sections[i].offsetTop + 100 ) {
                        $navs.eq(i).addClass("active").siblings().removeClass("active")
                        break;
                    }
                }
            }
        },
        watch: {
            scroll: function () {
                this.loadSroll()
            }
        },
        mounted() {
            window.addEventListener("scroll", this.handleScroll);
            window.addEventListener('scroll', this.dataScroll);
            this.getExamineNav();
        },
        destroyed() {
            //销毁监听
            window.removeEventListener("scroll", this.handleScroll);
            window.removeEventListener('scroll', this.dataScroll);
        },
    }
</script>

<style scoped>
    @import "../../assets/css/header.css";
    @import "../../assets/css/footer.css";

    .banner {
        height: 116px;
        background: url("../../assets/img/jbk_banner.png")center no-repeat;
    }

    .banner-text {
        width: 1200px;
        margin: 0 auto;
    }

        .banner-text h3 {
            padding-top: 25px;
            font-size: 26px;
            font-weight: normal;
            color: #ffffff;
        }

        .banner-text p {
            font-size: 18px;
            color: #ffffff;
        }

            .banner-text p a {
                color: #ffffff;
                font-size: 14px;
                margin: 0 5px;
            }

    .content-wrap {
        background: #fcfcfc;
    }

    .content-top {
        height: 64px;
        background: #FFFFFF;
        box-shadow: 2px 0 5px rgba(102, 102, 102, 0.08);
    }

    .diagnose-tab {
        width: 1200px;
        margin: 0 auto;
    }

        .diagnose-tab li, .diagnose-tab li a {
            margin-right: 20px;
            display: inline-block;
            line-height: 63px;
            color: #808080;
            font-size: 16px;
        }

            .diagnose-tab li.active a {
                color: #1fb7a6;
                border-bottom: 2px solid #1fb7a6;
            }

    .content {
        position: relative;
        width: 1200px;
        background: none;
        padding: 30px 0;
        margin: 0 auto;
    }

    .left-nav-wrap {
        position: absolute;
        width: 260px;
        max-height: 520px;
        box-shadow: 5px 0 8px rgba(224, 224, 224, 0.3);
    }

    .left-nav {
        width: 100%;
        background: #FFFFFF;
        padding: 20px 0;
        box-sizing: border-box;
    }

        .left-nav > h3 {
            padding-left: 20px;
            font-size: 18px;
            font-weight: normal;
        }

        .left-nav > ul > li {
            position: relative;
            padding: 15px 30px 15px;
            font-size: 16px;
            color: #666666;
            cursor: pointer;
        }

            .left-nav > ul > li span {
                position: absolute;
                left: 0;
                top: calc(50% - 4px);
                display: inline-block;
                width: 20px;
                height: 8px;
                margin-right: 20px;
            }

            .left-nav > ul > li:hover {
                color: #03b29e;
            }

    .left-nav > ul > li.active {
        color: #1fb7a6;
    }

    .left-nav > ul > li.active span {
        background: #03b29e;
    }

                .left-nav > ul > li.active span {
                    background: #03b29e;
                }

    .ny_container {
        margin-left: 280px;
        min-height: 690px;
    }

        .ny_container .zztabfel {
            padding: 5px 20px 10px;
            margin-bottom: 15px;
            background: #fff;
            box-shadow: 3px 0px 5px rgba(224, 224, 224, 0.3);
        }

            .ny_container .zztabfel h2 {
                font-size: 18px !important;
                line-height: 50px;
                font-weight: normal;
            }

            .ny_container .zztabfel:last-child {
                border-bottom: none;
            }

    .zztabfel a {
        margin: 0 8px 8px 0;
        float: left;
        padding: 7px 12px;
        display: inline-block;
        border: 1px #ddd solid;
        background: #fff;
        font-size: 15px;
        color: #666666;
        cursor: pointer;
    }

        .zztabfel a:hover {
            color: #1fb7a6;
            border: 1px #1fb7a6 solid;
        }
    /*    */

</style>
<style>
    .diagnose .bar-list .yysc{
        display: none !important;
    }
    .el-scrollbar__wrap {
        overflow: auto;
    }
</style>
