let Examine = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     * 获取检查逻辑图首页
     * @param {any} success
     * @param {any} error
     */
    this.Examines = function (success, error) {
        var url = ServiceBaseAddress + '/api/Examine';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 获取检查逻辑图详情
     * @param {any} success
     * @param {any} error
     */
    this.Detail = function (params, success, error) {
        var url = ServiceBaseAddress + '/api/Examine/' + params;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { Examine }